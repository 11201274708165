import { render, staticRenderFns } from "./Incentives.vue?vue&type=template&id=5f2f0c52&scoped=true"
import script from "./Incentives.vue?vue&type=script&lang=js"
export * from "./Incentives.vue?vue&type=script&lang=js"
import style0 from "./Incentives.vue?vue&type=style&index=0&id=5f2f0c52&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f2f0c52",
  null
  
)

export default component.exports